<template>
  <div class="marketingConfig">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "营销配置",
          search: [{
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: []
              }
            },
            {
              key: "date",
              label: "活动时间",
              type: "data-range"
            },
            {
              label: "活动名称",
              key: "name",
            },
            {
              key: "state",
              label: "活动状态",
              type: 'select',
              opt: {
                list: [{value: 0 ,label:'待生效'},{value: 1 ,label:'进行中'},{value: -2 ,label:'已中止'},{value: -1 ,label:'已结束'}],
              }
            },
          ],
          columns: [
            {
              label: "商家名称",
              key: "merchantName",
            },
            {
              label: "活动名称",
              key: "name",
              on: row => {
                _this.$router.push({
                  path:'/main/marketingConfig/detail/show',
                  query: {
                    id: row.id,
                    type: 'show'
                  }
                })
              }
            },
            {
              label: "活动时间",
              key: "timeStr",
            },
             {
              label: "活动状态",
              key: "stateStr",
            },
            {
              label: "操作",
              key: "status",
              type: "switch2",
              opt: {
                on(form) {
                  return _this.post(
                      "/mall-service/v1/activity/update-state", {
                        id: form.id,
                        status: !form.status ? 1 : 0
                      }, {
                        isUseResponse: true,
                      }
                    )
                    .then(res => {
                      if (res.data.code == 0) {
                        _this.$refs.myList.get()
                        return {
                          state: true
                        };
                      }
                    }).catch((res) => {
                      // return {
                      //   state: false,
                      //   msg: res.data.msg
                      // };
                    });
                }
              }
            }
          ],
          buttons: [{
            type: 0,
            on() {
              _this.$router.push({
                path: "/main/marketingConfig/detail/add",
                query: {
                  type: 'add'
                }
              })
            }
          },{
            type: 1,
            on(row) {
              if(row.data.state == -1) {
                _this.$message({
                    message: "已结束的活动不可编辑",
                    type: "warning"
                });
                return
              }
              _this.$router.push({
                path: "/main/marketingConfig/detail/edit",
                query: {
                  id: row.id,
                  state: row.data.state,
                  type: 'edit'
                }
              })
            }
          },{
            type: 2,
            on(data) {
                data.ids = data.ids.join(',')
                let isProceed = false
                data.list.forEach(item => {
                  if(item.state == 1) {
                    isProceed = true
                  }
                })
                if (isProceed) {
                  _this.$message({
                      message: "进行中的活动不可删除",
                      type: "warning"
                  });
                  return
                }
                const h = _this.$createElement;
                _this.$msgbox({
                    title: "删除提示",
                    message: h("p", null, [
                      h(
                        "span", {
                          style: "color: #333;font-size: 16px;margin-left:98px"
                        },
                        "确定要删除该活动吗？"
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    _this.post("mall-service/v1/activity/delete?ids=" + data.ids)
                      .then(function () {
                        _this.$message({
                          showClose: true,
                          message: "删除活动成功！",
                          type: "success"
                        });
                        data.refresh();
                      });
                  });
              }
          },]
        },
      };
    },
    created() {
      console.log("marketingConfig-list created!!");

    },
    async activated() {
      console.log("marketingConfig-list activated!!");
      try {
        this.merchantFlag = this.getLoginType('loginType');
        if (this.merchantFlag) {
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
          this.opt.columns.forEach((item,index) => {
            if (item.key == 'status') {
              this.opt.columns.splice(index,1)
            }
          })
        }
      } catch (err) {
        console.log(error)
      }
    },
    deactivated() {
      this.dialogVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm,
          startDate: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          endDate: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") :
            null,
        };
        if (dto.date) {
          delete dto.date
        }
        this.post("/mall-service/v1/activity/page", dto, {
          isUseResponse: true
        }).then(res => {
          let obj = {
            data: [],
            total: 0
          }
          obj.data = res.data.data.data;
          obj.total = res.data.data.total
          res.data.data.data.forEach(item => {
            item.timeStr = item.startDate + ' ~ ' +item.endDate
            item.stateStr = ['已中止','已结束','待生效','进行中'][item.state + 2]
            if (item.state ==0 || item.state == 1 ) {
              item.status = true
            } else {
              item.status = false
            }
            if(item.state == -1) {
              this.opt.columns.forEach((val, index) => {
                if (val.key == 'status') {
                  item.isShow = false
                }
              })
            }
            else {
              this.opt.columns.forEach((val, index) => {
                if (val.key == 'status') {
                  item.isShow = true
                }
              })
            }
          })
          opt.cb(obj);
        });
      },
    }
  };
</script>
<style lang="scss">
 .app-list .row-nav-vice {
  cursor: pointer;
  border-bottom:none !important;
  color: #30BAC1;
}
</style>